<template>
    <div class="page">
        <div class="header">
            <div class="title">
                <img src="../../assets/logo/homeLogo.png" class="logo" @click="toHome" />
                <div class="info">咨询电话：4008-598-698</div>
            </div>
        </div>

        <div class="banner1">
            <img src="../../assets/school/banner1.png" />
        </div>

        <div style="height:10px"></div>

        <div class="info_title_box">
            <div class="info_title">
                <img src="../../assets/school/info1_title.png" />
            </div>
        </div>
        <div class="email">
            <div class="item">
                <input type="text" placeholder="您的称呼" v-model.trim="createForm.nickname" />
            </div>
            <div class="item">
                <input type="text" placeholder="您的电话" v-model.trim="createForm.phone" />
            </div>
            <div class="item btn" @click="Submit">立即提交</div>
        </div>

        <div class="banner2">
            <img src="../../assets/school/banner2.png" />
            <div class="tab_view">
                <div class="title">·毕业颁发多种职业资格证书 行业认可·</div>
                <div class="tab_box">
                    <div class="left">
                        <div class="btn select">行业证书</div>
                        <div class="btn" style="visibility: hidden;">国际证书</div>
                        <div class="btn" style="visibility: hidden;">国际证书</div>
                    </div>
                    <div class="right">
                        <img src="../../assets/school/zhengshu.png" />
                    </div>
                </div>
            </div>
        </div>

        <div class="info_mess info_mess_video">
            <div class="info_mess_box">
                <img src="../../assets/school/info1.png" />
            </div>
            <div class="video">
                <video autoplay="autoplay" controls loop src="../../assets/school/video.mp4" />
            </div>
        </div>

        <div class="info_mess">
            <div class="info_mess_box" v-for="(item,index) in infoImgList" :key="index">
                <img :src="item" />
            </div>
        </div>

        <div class="info_title_box">
            <div class="info_title">
                <img src="../../assets/school/info2_title.png" />
            </div>
            <swiper ref="mySwiper1" :options="swiperOptions1">
                <swiper-slide v-for="(item,index) in teacherImgList" :key="index" class="teacher_img_swiper">
                    <img :src="item" />
                </swiper-slide>
                <div class="swiper-pagination" slot="pagination"></div>
            </swiper>
        </div>

        <div class="info_title_box">
            <div class="info_title">
                <img src="../../assets/school/info3_title.png" />
            </div>
        </div>

        <div class="class_bar">
            <div
                v-for="(item,index) in classBar"
                :key="index"
                :class="[index == selectBar ? ' active' : '']"
                @click="selectBar = index;classSelect = item.obj"
            >{{ item.title }}</div>
        </div>

        <div class="class_bar_img">
            <swiper class="class_bar_img_swiper" :options="swiperOptions2">
                <template v-for="(item,index) in tabList">
                    <swiper-slide :key="index" v-show="classSelect == item.type">
                        <img :src="item.url" />
                    </swiper-slide>
                </template>
            </swiper>
        </div>

        <div class="downlod_box">
            <div class="logo">
                <img src="../../assets/school/log.png" />
            </div>
            <div class="title">
                还可下载梵羽云app了解更多惊喜，支持OS、安卓版本下载,
                可前往各大应用市场下载
            </div>
            <div class="btn">
                <!-- <div class="black_btn" v-for="(item, index) in btnList" :key="index">
                    <img class="black_btn_image" :src="item.url" />
                </div>-->
                <div
                    class="black_btn"
                    :class="[index == selectBtn ? ' black_btn_select' : '']"
                    v-for="(item, index) in btnList"
                    :key="index"
                >
                    <img class="black_btn_image" :src="item.url" @click="selectBtn = index" />
                    <div class="black_btn_qr" v-show="index == selectBtn">
                        <img :src="item.code" />
                    </div>
                </div>
            </div>
        </div>

        <div class="footer">
            <img src="../../assets/school/footer.png" />
        </div>

        <Footer />
    </div>
</template>

<script>
import 'swiper/dist/css/swiper.css';
import { swiper, swiperSlide } from 'vue-awesome-swiper';
import Footer from '@/components/footer.vue';

import { osCmsAsk } from '@/api/ask';
export default {
    metaInfo: {
        title: '梵羽国际瑜伽学院', // set a title
        meta: [
            {
                // set meta
                name: 'keyWords',
                content: '关于梵羽国际瑜伽学院',
            },
            {
                name: 'description',
                content:
                    '梵羽国际瑜伽,隶属于梵羽集团旗下品牌。8年品牌,百万会员,至今已培养15000+瑜伽教练。是深圳市瑜伽协会常务副会长单位,深圳瑜伽教练培训高端学府;旗下拥有100+家连锁分店及一所1800平方培训学院,分布于珠三角各区域。国内首创“练瑜伽,学舞蹈,0收费”之会员盈利式经营模式;国内瑜伽界公认系统、专业的瑜伽老师培训体系;沈阳体育学院-大学生实习就业基地;沈阳体育学院-瑜伽教学科研基地;沈阳体育学院-联合教培基地;亚洲瑜伽联盟协会、深圳市瑜伽协会指定培训基地,国内专业瑜伽舞蹈培训学府。',
            },
        ],
    },
    name: 'FaceIndex',
    components: {
        swiper,
        swiperSlide,
        Footer,
    },
    data() {
        return {
            // 提交的信息
            createForm: {},
            selectBar: 0,
            selectBtn: 1,
            infoImgList: [require('../../assets/school/info2.png'), require('../../assets/school/info3.png')],
            teacherImgList: [require('../../assets/school/teacher0.png'), require('../../assets/school/teacher1.png'), require('../../assets/school/teacher2.png')],
            tabList: [],
            classSelect: 'biye',
            classBar: [
                {
                    title: '毕业留念',
                    obj: 'biye',
                },
                {
                    title: '上课动态',
                    obj: 'shangke',
                },
                {
                    title: '户外体式拍摄',
                    obj: 'huwai',
                },
                {
                    title: '个人体式展示',
                    obj: 'tishi',
                },
            ],
            btnList: [
                {
                    url: require('../../assets/component/android.png'),
                    code: require('../../assets/qrcode/android.png'),
                },
                {
                    url: require('../../assets/component/apple.png'),
                    code: require('../../assets/qrcode/ios.png'),
                },
                {
                    url: require('../../assets/component/wx.png'),
                    code: require('../../assets/qrcode/wx.png'),
                },
            ],
            biyeList: [
                {
                    type: 'biye',
                    url: require('../../assets/school/biye/01.jpg'),
                },
                {
                    type: 'biye',
                    url: require('../../assets/school/biye/02.jpg'),
                },
                {
                    type: 'biye',
                    url: require('../../assets/school/biye/03.jpg'),
                },
                {
                    type: 'biye',
                    url: require('../../assets/school/biye/04.jpg'),
                },
                {
                    type: 'biye',
                    url: require('../../assets/school/biye/05.jpg'),
                },
                {
                    type: 'biye',
                    url: require('../../assets/school/biye/06.jpg'),
                },
            ],
            huwaiList: [
                {
                    type: 'huwai',
                    url: require('../../assets/school/huwai/01.jpg'),
                },
                {
                    type: 'huwai',
                    url: require('../../assets/school/huwai/02.jpg'),
                },
                {
                    type: 'huwai',
                    url: require('../../assets/school/huwai/03.jpg'),
                },
                {
                    type: 'huwai',
                    url: require('../../assets/school/huwai/04.jpg'),
                },
                {
                    type: 'huwai',
                    url: require('../../assets/school/huwai/05.jpg'),
                },
                {
                    type: 'huwai',
                    url: require('../../assets/school/huwai/06.jpg'),
                },
            ],
            shangkeList: [
                {
                    type: 'shangke',
                    url: require('../../assets/school/shangke/01.jpg'),
                },
                {
                    type: 'shangke',
                    url: require('../../assets/school/shangke/02.jpg'),
                },
                {
                    type: 'shangke',
                    url: require('../../assets/school/shangke/03.jpg'),
                },
                {
                    type: 'shangke',
                    url: require('../../assets/school/shangke/04.jpg'),
                },
                {
                    type: 'shangke',
                    url: require('../../assets/school/shangke/05.jpg'),
                },
                {
                    type: 'shangke',
                    url: require('../../assets/school/shangke/06.jpg'),
                },
                {
                    type: 'shangke',
                    url: require('../../assets/school/shangke/07.jpg'),
                },
            ],
            tishiList: [
                {
                    type: 'tishi',
                    url: require('../../assets/school/tishi/01.jpg'),
                },
                {
                    type: 'tishi',
                    url: require('../../assets/school/tishi/02.jpg'),
                },
                {
                    type: 'tishi',
                    url: require('../../assets/school/tishi/03.jpg'),
                },
                {
                    type: 'tishi',
                    url: require('../../assets/school/tishi/04.jpg'),
                },
                {
                    type: 'tishi',
                    url: require('../../assets/school/tishi/05.jpg'),
                },
                {
                    type: 'tishi',
                    url: require('../../assets/school/tishi/06.jpg'),
                },
                {
                    type: 'tishi',
                    url: require('../../assets/school/tishi/07.jpg'),
                },
                {
                    type: 'tishi',
                    url: require('../../assets/school/tishi/08.jpg'),
                },
                {
                    type: 'tishi',
                    url: require('../../assets/school/tishi/09.jpg'),
                },
            ],
            swiperOptions1: {
                pagination: {
                    el: '.swiper-pagination', //与slot="pagination"处 class 一致
                    clickable: true, //轮播按钮支持点击
                },
            },
            swiperOptions2: {
                slidesPerView: 'auto',
                spaceBetween: 30,
                freeMode: true,
            },
        };
    },
    created() {
        this.tabList = [...this.biyeList, ...this.huwaiList, ...this.shangkeList, ...this.tishiList];
    },
    mounted() {},

    methods: {
        toHome() {
            this.$router.push({
                name: 'Home',
            });
        },
        Submit() {
            if (!this.createForm.nickname || !this.createForm.phone || !/^1[0-9]{10}$/.test(this.createForm.phone)) {
                alert('请正确填写姓名/电话信息！');
            } else {
                osCmsAsk(this.createForm)
                    .then(res => {
                        if (res.status == 'OK') {
                            alert(res.message);
                            this.createForm = {};
                        } else {
                            alert(res.message);
                        }
                    })
                    .catch(err => {
                        alert(err.message);
                    });
            }
        },
    },
};
</script>

<style lang="scss" scoped>
$headerColor: #9a609c;

.page {
    height: 100%;
    width: 100%;
    background-color: #fff;

    .header {
        color: #fff;
        font-size: 14px;
        background-color: $headerColor;

        .title {
            height: 70px;
            max-width: 1200px;
            margin: 0 auto;

            display: flex;
            justify-content: space-between;
            align-items: center;
            .logo {
                height: 35px;
                cursor: pointer;
            }
        }
    }

    .banner1 {
        width: 100%;
        max-height: 820px;
        overflow: hidden;
        img {
            width: 100%;
        }
    }

    .email {
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-bottom: 60px;

        .item {
            width: 480px;
            height: 60px;
            background: #dddddd;
            border-radius: 44px;
            margin-top: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            overflow: hidden;

            input {
                outline-style: none;
                border-width: 0;
                padding: 0 35px;
                width: 100%;
                font-size: 16px;
                color: #666;
                background: #dddddd;

                &::-webkit-input-placeholder {
                    color: #acacac;
                    font-size: 16px;
                }

                &:-webkit-autofill {
                    box-shadow: 0 0 0px 1000px #dddddd inset !important;
                }
            }
        }

        .btn {
            font-size: 20px;
            text-align: center;
            line-height: 60px;
            background: #9a609c;
            color: #fff;
            cursor: pointer;
        }
    }

    .banner2 {
        width: 100%;
        max-height: 750px;
        position: relative;
        overflow: hidden;
        img {
            width: 100%;
        }

        .tab_view {
            width: 60%;
            height: 35vw;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            .title {
                font-size: 3vw;
                text-align: center;
                color: #fff;
            }

            .tab_box {
                display: flex;
                justify-content: space-between;
                align-items: center;
                height: 70%;
                margin-top: 5%;

                .left {
                    width: 25%;
                    height: 100%;

                    display: flex;
                    flex-direction: column;
                    justify-content: space-around;

                    .btn {
                        width: 60%;
                        font-size: 1.4vw;
                        color: #9a609c;
                        text-align: center;
                        padding: 1vw;
                        background-color: #ffffff;
                        border-radius: 5px;
                        cursor: pointer;
                    }

                    .select {
                        color: #fff;
                        background-color: #9a609c;
                    }
                }

                .right {
                    width: 75%;
                    height: 100%;
                    overflow: hidden;

                    img {
                        width: 100%;
                    }
                }
            }
        }
    }

    .info_mess {
        width: 100%;

        .info_mess_box {
            max-width: 1000px;
            margin: 0 auto;
            margin-top: 70px;
            overflow: hidden;

            img {
                width: 100%;
            }
        }
    }

    .info_mess_video {
        position: relative;

        .video {
            width: 30vw;
            height: 19vw;
            background: #000;
            z-index: 999;

            position: absolute;
            right: 15%;
            top: 30%;

            video {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
    }

    .info_title_box {
        max-width: 1000px;
        margin: 0 auto;
        margin-top: 70px;

        .info_title {
            max-width: 1000px;
            overflow: hidden;
            img {
                width: 100%;
            }
        }
    }

    .class_bar {
        color: #393939;
        font-size: 18px;
        max-width: 1000px;
        height: 70px;
        line-height: 70px;
        background: #ffffff;
        box-shadow: 0px 3px 17px rgba(16, 55, 72, 0.11);
        border-radius: 25px;
        margin: 0 auto;
        margin-top: 25px;

        display: flex;
        justify-content: space-around;
        align-items: stretch;

        div {
            cursor: pointer;
        }

        .active {
            color: #9a609c;
            position: relative;

            &::after {
                content: '';
                width: 40px;
                height: 5px;
                background-color: #9a609c;
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
            }
        }
    }

    .class_bar_img {
        width: 100%;
        margin: 0 auto;
        margin-top: 40px;
    }

    .downlod_box {
        margin: 28px auto 20px auto;
        .logo {
            margin: 0 auto;
            width: 55px;
            height: 55px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 10px;
            box-shadow: 0px 3px 17px rgba(16, 55, 72, 0.11);
            img {
                width: 50px;
            }
        }

        .title {
            font-size: 17px;
            font-weight: 400;
            line-height: 28px;
            color: #a2a2a2;
            text-align: center;
            margin: 0 auto;
            margin-top: 20px;
            margin-bottom: 30px;
            max-width: 500px;
        }

        .btn {
            display: flex;
            flex-wrap: wrap;
            // justify-content: space-around;
            // align-items: center;
            max-width: 600px;
            margin: 0 auto;
        }

        // // 下载按钮
        // .black_btn {
        //     cursor: pointer;
        //     .black_btn_image {
        //         width: 120px;
        //         height: 40px;
        //     }
        // }

        // 下载按钮
        .black_btn {
            padding: 15px 25px;
            cursor: pointer;
            transition: 0.3s;
            .black_btn_image {
                width: 150px;
                height: 50px;
            }

            .black_btn_qr {
                width: 150px;
                height: 150px;
                background-color: #fff;
                margin-top: 10px;
                img {
                    width: 100%;
                }
            }
        }

        .black_btn_select {
            height: 220px;
            background-color: rgba(0, 0, 0, 0.4);
            padding: 15px 25px;
            border-radius: 7px;
            overflow: hidden;
        }
    }

    .footer {
        margin-top: 50px;
        width: 100%;
        max-height: 820px;
        overflow: hidden;
        img {
            width: 100%;
        }
    }
}
</style>

<style lang="scss">
.swiper-pagination {
    .swiper-pagination-bullet {
        width: 40px;
        height: 10px;
        display: inline-block;
        background: #fff !important;
        opacity: 0.2;
        margin: 0 5px;
        border-radius: 0px;
        transition: opacity 0.2s, background-color 0.2s, width 0.2s;
        transition-delay: 0.2s, 0.2s, 0s;
    }
    .swiper-pagination-bullet-active {
        opacity: 1;
        background-color: #ffffff !important;
        width: 40px;
        transition-delay: 0s;
    }
}

.class_bar_img_swiper {
    height: 250px;
    width: 100%;

    .swiper-slide {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-weight: bold;
        background-color: #ccc;
        width: 330px;
        border-radius: 40px;
        overflow: hidden;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}

.teacher_img_swiper {
    max-height: 550px;
    width: 100%;
    img {
        width: 100%;
    }
}
</style>