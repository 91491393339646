import request from '@/utils/request'

//【前台】添加意向
export function osCmsAsk(data) {
    let url = '/os-cms/ask'
    if (data.id) {
        url += `?id=${data.id}`
    }
    return request({
        url,
        method: 'post',
        data
    })
}